import styled, { keyframes } from "styled-components";

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  margin-right: 45px;
  padding: 50px 20px;
  background-color: ${({ theme }) => theme.backgroundCard};
  color: ${({ theme }) => theme.text};
  border-radius: 8px;
  opacity: 0;

  animation: ${opacityAnimation} 2s ease-out forwards;

  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize};
  line-height: ${({ theme }) => theme.lineHeight};
  padding: 0px 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    padding: 10px;
    font-size: ${({ theme }) => theme.fontSizeMinor};
  }
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  object-fit: cover;
  border: 4px solid ${({ theme }) => theme.item};
`;
