import React from "react";
import * as S from "./Timeline.styles";

interface TimelineProps {
  items: React.ReactNode[];
}

export const Timeline: React.FC<TimelineProps> = ({ items }) => {
  return (
    <S.TimelineContainer>
      {items.map((item, index) => (
        <S.TimelineItemContainer key={index} $delay={(index + 2) * 0.5}>
          <S.Circle />
          {item}
        </S.TimelineItemContainer>
      ))}
    </S.TimelineContainer>
  );
};
