export const aboutES = {
  job: `¡Hola! Me llamo Jonatan Pérez Rodenas y este es mi humilde rincón en la red.
    J. Rodenper es el pseudónimo que uso como desarrollador. Soy un licenciado en Matemáticas apasionado por la programación.
    Me he especializado en la creación de herramientas para diseño CAD y el desarrollo web.
    Cuento con más de 16 años de experiencia en la industria. Mi objetivo es seguir aprendiendo y mantener mis
    conocimientos al día con las nuevas tecnologías. `,

  hobbies: `En mi tiempo libre, disfruto de jugar al ajedrez y tocar la guitarra.
    Estoy siempre en busca de nuevos desafíos y oportunidades para crecer
    tanto profesional como personalmente.`,

  ending: `Si mi perfil te ha parecido interesante no dudes en contactar conmigo.`,
};

export const aboutEN = {
  job: `Hello! My name is Jonatan Pérez Rodenas and this is my modest corner on the web. J. Rodenper is the nickname
    I use as a developer. I have a degree in Mathematics and I have a great passion for programming.
    I have specialized in creating tools for CAD and web development. With over 16 years of experience in the industry,
    my goal is to continue learning and stay up to date with new technologies. `,

  hobbies: `In my free time, I enjoy playing chess and playing the guitar.
    I am always looking for new challenges and opportunities to grow
    both professionally and personally.`,

  ending: `If my profile has caught your interest, don't hesitate to contact me.`,
};
