import styled from "styled-components";

export const PreviewItemContainer = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 200px;
  margin: 20px 0;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  border-radius: 10px;
  border: 4px solid ${({ theme }) => theme.item};

  cursor: pointer;

  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;

export const Content = styled.div`
  padding: 0 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: ${({ theme }) => theme.fontSize};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizeMinor};
  }
`;
