import styled, { keyframes } from "styled-components";

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const typingAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 200px;
  }
`;

const blinkingCursorAnimation = keyframes`
  from {
    border-right-color: transparent;
  }
  to {
    border-right-color:  ${({ theme }) => theme.color};
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

interface ProfileImageProps {
  $image1: string;
  $image2: string;
}

export const ProfileImage = styled.div<ProfileImageProps>`
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  min-width: 50px;
  min-height: 50px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 20px;
  opacity: 0;

  border: 4px solid ${({ theme }) => theme.item};

  scale(1);

  background-image: url(${(props) => props.$image1});
  background-size: cover;

  animation: ${opacityAnimation} 2s ease-out forwards;

  transition: transform 0.3s ease-out, background-image  0.5s 0.1s;
  &:hover {
    transform: scale(1.2);
    background-image: url(${(props) => props.$image2});
  }

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
    margin-top: 20px;
    margin-bottom: 0px;
}
`;

export const TextContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 25px;
  padding: 0px 30px;
  opactity: 0;

  border: 4px solid ${({ theme }) => theme.item};
  background: ${({ theme }) => theme.backgroundCard};
  animation: ${opacityAnimation} 0.5s ease-out forwards;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    padding: 0px 15px;
    border: 4px solid ${({ theme }) => theme.item};
    background: ${({ theme }) => theme.backgroundCard};
    animation: ${opacityAnimation} 0.5s ease-out forwards;
    list-style-type: none;
  }
`;

export const Title = styled.li`
  color: ${({ theme }) => theme.subtitle2};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.background};

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: scale(0.5);
    background: ${({ theme }) => theme.background};
  }
`;

export const TextWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
`;

export const AnimatedText = styled.h2`
  color: ${({ theme }) => theme.subtitle2};
  display: inline-block;
  overflow: hidden;
  border-right: 2px solid ${({ theme }) => theme.subtitle2};
  white-space: nowrap;
  animation: ${typingAnimation} 1s steps(40, end),
    ${blinkingCursorAnimation} 0.5s infinite alternate;
`;
