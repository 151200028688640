import React, { ReactNode } from "react";
import * as S from "./Sections.styles";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

interface SectionProps {
  id: string;
  title?: string;
  children?: ReactNode;
  delay?: number;
  padding?: number;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const Section: React.FC<SectionProps> = ({
  id,
  title,
  children,
  delay = 0,
  padding = 20,
  icon: Icon,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <S.Container id={id} $delay={delay} $padding={padding}>
      {title ? (
        <S.Title>
          {Icon && (
            <S.SVGContainer>
              <Icon
                stroke={theme.item}
                fill={theme.backgroundCard}
                height={32}
                width={32}
              />
            </S.SVGContainer>
          )}
          {t(title)}
        </S.Title>
      ) : null}
      {children}
    </S.Container>
  );
};
