export const timelineItemsES = [
  {
    date: "Marzo 2023 - Presente",
    company: "SEDDI",
    description: `Trabajo dentro del equipo front-end desarrollando aplicaciones web para diseño de ropa.
        Creación y mantenimiento tanto de interfaces (usando React y Redux) como de herramientas CAD (2D y 3D).
        Participo en la construcción de múltiples frameworks propios, incluyendo un robusto conjunto de
        componentes personalizables para React. Desarrollos puntuales en el back-end (NestJS).
        Trabajo en remoto en un entorno internacional.`,
    descriptionVideo: `A continuación muestro una breve demo de Author. He participado de forma muy activa en las
        interfaces de usuario que se muestran en el vídeo, así como en todas las funcionalidades y herramientas 2D
        y 3D que aparecen.`,
    videoId: "1gRvKmM8yP4",
  },
  {
    date: "Junio 2021 - Marzo 2023",
    company: "VOXELCARE",
    description: `Trabajé como front-end (JS/TS, React, Redux...) y desarrollador CAD/CAM para diseño y fabricación de plantillas de calzado.
        Desarrollé algoritmos de reconstrucción de entidades geométricas a partir de datos adquiridos mediante sistemas
        de digitalización tridimensional. Entre otras muchas tareas, participé en la creación de frameworks propios para
        la visualización de elementos en entornos 3D.`,
    descriptionVideo: `El vídeo que aparece abajo muestra una demo general de los desarrollos que llevamos a cabo en Voxelcare.
        Una de mis tareas principales era el mantenimiento de la parte CAD, creando y mejorando las herramientas para el
        diseño de suelas.`,
    videoId: "D_SFoirsv4g",
  },
  {
    date: "Enero 2008 - Mayo 2021",
    company: "INESCOP",
    description: `Participé en el desarrollo y mantenimiento de numerosas aplicaciones de escritorio (C++) y
        herramientas de diseño CAD/CAM para calzado, la mayoría como desarrollador, pero también liderando proyectos.
        Desarrollé plugins para Rhinoceros(C#). Entre las funciones de mi día a día destacaba el trabajo con NURBS
        (Non-Unifrom Rational B-Splines) y el desarrollo de algoritmos matemáticos de todo tipo.
        Además, contribui en la generación de mapas de texturizado para modelos 3D y en la configuración de escenarios
        de renderizado.`,
    descriptionVideo: `Aquí muestro una demo de un diseño realizado con ICad3D+, uno de los programas en los que más
        involucrado estuve durante mi participación en la empresa, tanto a nivel de UI como de algoritmos matemáticos.`,
    videoId: "eqQoeVIm2sU",
  },
];

export const timelineItemsEN = [
  {
    date: "March 2023 - Present",
    company: "SEDDI",
    description: `I work within the front-end team developing web applications for clothing design.
        My task are about the creation and maintenance of both interfaces (using React and Redux) and CAD tools (2D and 3D).
        I am involved in building multiple proprietary frameworks, including a robust set of customizable components for React.
        Occasional back-end developments (NestJS). I work remotely in an international team.`,
    descriptionVideo: `Below I present a brief demo of Author. I have been very actively involved in the user
        interfaces shown in the video, as well as in all the 2D and 3D functionalities and tools that appear.`,
    videoId: "1gRvKmM8yP4",
  },
  {
    date: "June 2021 - March 2023",
    company: "VOXELCARE",
    description: `I worked as a front-end developer (JS/TS, React, Redux...) and CAD/CAM developer for the design and
        manufacturing of shoe insoles. I developed algorithms for reconstructing geometric entities from data
        acquired through three-dimensional scanning systems. Among many other tasks, I participated in the creation
        of custom frameworks for visualization of elements in 3D environments.`,
    descriptionVideo: `The video below showcases a general demo of the developments we undertook at Voxelcare.
        One of my main tasks was the maintenance of the CAD part, creating and improving the tools for the sole design.`,
    videoId: "D_SFoirsv4g",
  },
  {
    date: "January 2008 - May 2021",
    company: "INESCOP",
    description: `I participated in the development and maintenance of numerous desktop applications (C++) and
        CAD/CAM design tools for footwear, mostly as a developer, but also leading projects. I developed plugins
        for Rhinoceros (C#). Among my daily tasks was working with NURBS (Non-Uniform Rational B-Splines) and developing
        various types of mathematical algorithms. Additionally, I contributed to the generation of texture maps for
        3D models and the setup of rendering scenarios.
      `,
    descriptionVideo: `Here I present a demo of a design created with ICad3D+, one of the programs where I was most
        involved during my time at the company, both at the UI level and in terms of mathematical algorithms.`,
    videoId: "eqQoeVIm2sU",
  },
];
