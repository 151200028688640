import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-color: ${({ theme }) => theme.background};
`;
