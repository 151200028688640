import styled, { css, keyframes } from "styled-components";

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Button = styled.a<{ offset?: string; $animate?: string }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.item};
  margin: 10px ${(props) => (props.offset ? props.offset : "10px")};
  color: ${({ theme }) => theme.color};
  cursor: pointer;
  background: ${({ theme }) => theme.backgroundItem};

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.$animate === "true"
      ? css`
          animation: ${opacityAnimation} 2s ease-out forwards;
        `
      : ""}

  transition: transform 0.3s ease-out;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.2);
    }
  }
`;
