import { BackgroundContainer } from "./components/BackgroundContainer";
import { TopBar } from "./components/TopBar";
import * as S from "./App.styles";
import { HomeSection } from "./components/HomeSection";
import { ExperienceSection } from "./components/ExperienceSection";
import { ProjectSection } from "./components/ProjectSection";
import { AboutSection } from "./components/AboutSection";
import { Footer } from "./components/Footer";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./themes";
import { useEffect, useState } from "react";
import { LanguageProvider } from "./LanguageContext";
import { SkillSection } from "./components/SkillSection";

function App() {
  const [theme, setTheme] = useState(darkTheme);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme === "Light" ? lightTheme : darkTheme);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme.type === "Light" ? "Dark" : "Light";
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <S.GlobalStyles />
      <LanguageProvider>
        <TopBar toggleTheme={toggleTheme} currentTheme={theme} />
        <BackgroundContainer>
          <S.BodyContainer>
            <HomeSection />
            <ExperienceSection />
            <ProjectSection />
            <SkillSection />
            <AboutSection />
          </S.BodyContainer>
        </BackgroundContainer>
        <Footer />
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
