import React from "react";
import * as S from "./ButtonLink.styles";

interface ButtonProps {
  offset?: string;
  animate?: string;
  children?: React.ReactNode;
  href?: string;
  target?: string;
  download?: boolean;
  onClick?: () => void;
}

export const ButtonLink: React.FC<ButtonProps> = ({
  offset,
  animate,
  children,
  href,
  target,
  download,
  onClick,
}) => {
  return (
    <S.Button
      offset={offset}
      $animate={animate}
      href={href}
      target={target}
      download={download}
      onClick={onClick}
    >
      {children}
    </S.Button>
  );
};
