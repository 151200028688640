import React, { useState } from "react";
import { ReactComponent as MoonIcon } from "../icons/moon.svg";
import { ReactComponent as SunIcon } from "../icons/sun.svg";
import { ReactComponent as SpainFlag } from "../icons/spain.svg";
import { ReactComponent as UKFlag } from "../icons/uk.svg";
import { ReactComponent as MenuIcon } from "../icons/menuBurguer.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import * as S from "./TopBar.styles";
import { useTheme } from "styled-components";
import { useLanguage } from "../../LanguageContext";
import { useTranslation } from "react-i18next";
import { EMAIL } from "../../constants";

interface TopBarProps {
  toggleTheme: () => void;
  currentTheme: object;
}

export const TopBar: React.FC<TopBarProps> = ({ toggleTheme }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language, toggleLanguage } = useLanguage();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    const offset = 100;
    const element = document.getElementById(id);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      setIsMenuOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <S.Container>
      <S.LeftContent>
        <S.Title>
          <S.SVGContainer
            src={`${process.env.PUBLIC_URL}/rodenperLogo.svg`}
            alt="RODENPER"
          />
        </S.Title>
      </S.LeftContent>
      <S.NavContent $isMenuOpen={isMenuOpen}>
        <a href="#experience" onClick={(e) => handleScroll(e, "experience")}>
          {t("experience")}
        </a>
        <a href="#projects" onClick={(e) => handleScroll(e, "projects")}>
          {t("projects")}
        </a>
        <a href="#skills" onClick={(e) => handleScroll(e, "skills")}>
          {t("skills")}
        </a>
        <a href="#about" onClick={(e) => handleScroll(e, "about")}>
          {t("about")}
        </a>
        <a href={`mailto:${EMAIL}`}>{t("contact")}</a>
      </S.NavContent>
      <S.RightContent>
        <S.Button aria-label="Tema" onClick={toggleTheme}>
          {theme.type === "Dark" ? <MoonIcon /> : <SunIcon />}{" "}
        </S.Button>
        <S.Button
          aria-label="Lenguaje"
          onClick={toggleLanguage}
          className="language-toggle-button"
        >
          {language === "es" ? (
            <SpainFlag width="24" height="24" />
          ) : (
            <UKFlag width="24" height="24" />
          )}
        </S.Button>
        <S.MenuButton onClick={toggleMenu}>
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </S.MenuButton>
      </S.RightContent>
    </S.Container>
  );
};
