import React, { useState } from "react";
import * as S from "./PreviewItem.styles";

interface PreviewItemdProps {
  imageUrl: string;
  videoUrl: string;
  title: string;
  onClick: () => void;
}

export const PreviewItem: React.FC<PreviewItemdProps> = ({
  imageUrl,
  videoUrl,
  title,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <S.PreviewItemContainer
      onMouseEnter={(e) => {
        const video = e.currentTarget.querySelector("video");
        if (video) {
          video.play();
          video.style.opacity = "1";
          setHover(true);
        }
      }}
      onMouseLeave={(e) => {
        const video = e.currentTarget.querySelector("video");
        if (video) {
          video.pause();
          video.style.opacity = "0";
          setHover(false);
        }
      }}
    >
      <S.Image src={imageUrl} alt={title} />
      <S.Video src={videoUrl} muted loop onClick={onClick} />
      <S.Content>{hover && <h3>{title}</h3>}</S.Content>
    </S.PreviewItemContainer>
  );
};
