import React, { ReactNode } from "react";
import * as S from "./Button.styles";

interface ButtonProps {
  text?: string;
  child?: ReactNode;
  href?: string;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  text,
  child,
  href,
  onClick,
}) => {
  return (
    <S.Container>
      <S.Animation>
        <S.Content href={href} onClick={onClick}>
          {text && text}
          {child && child}
        </S.Content>
      </S.Animation>
    </S.Container>
  );
};
