import styled, { keyframes } from "styled-components";

interface TimelineItemContainerProps {
  $delay: number;
}

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px 20px;
  padding: 0 20px;
  border-left: 2px solid ${({ theme }) => theme.item};
  @media (max-width: 768px) {
    margin-left: 0px;
    padding-left: 0px;
    border-left: none;
  }
`;

export const TimelineItemContainer = styled.div<TimelineItemContainerProps>`
  display: flex;
  align-items: flex-start;
  margin: 10px 10px;
  position: relative;
  opacity: 0;
  animation: ${slideIn} 0.5s ease-out forwards;
  animation-delay: ${({ $delay }) => $delay}s;
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.item};
  position: absolute;
  left: -11px;
  @media (max-width: 768px) {
    display: none;
  }
`;
