import styled from "styled-components";

export const Container = styled.header`
  position: fixed;
  width: calc(100% - 17px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(255, 255, 255, 0);
  color: #ddd;
  border-bottom: 1px solid ${({ theme }) => theme.item};
  font-family: sans-serif;
  backdrop-filter: blur(6px);
  z-index: 100;
`;

export const Title = styled.div`
  margin: 10px;
`;

export const SVGContainer = styled.img`
  width: 100%;
  height: 100%;
  filter: ${({ theme }) =>
    theme.type === "Dark" ? "hue-rotate(130deg)" : "sepia(100%)"};
`;

export const LeftContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Button = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.item};
  border: none;
  transition: color 0.7s ease, transform 0.7s ease;
  @media (hover: hover) {
    &:hover {
      transform: scale(1.5);
      color: ${({ theme }) => theme.higlight};
      cursor: pointer;
    }
  }
`;

export const NavContent = styled.nav<{ $isMenuOpen: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  a {
    color: ${({ theme }) => theme.subtitle2};
    margin-right: 30px;
    text-decoration: none;
    transition: color 0.7s ease, transform 0.7s ease;
    &:hover {
      transform: scale(1.5);
      color: ${({ theme }) => theme.higlight};
    }
  }

  @media (max-width: 768px) {
    display: ${({ $isMenuOpen }) => ($isMenuOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.background};
    border-bottom: 1px solid ${({ theme }) => theme.item};
    padding: 10px 0;

    a {
      margin: 10px 0;
    }
  }
`;

export const RightContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
`;

export const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.item};
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;
