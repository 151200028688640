import React from "react";
import * as S from "./ExperienceSection.styles";
import YoutubeItem from "./YoutubeItem";

interface SaberMasProps {
  description?: string;
  videoId: string;
  title: string;
}

export const Knowmore: React.FC<SaberMasProps> = ({
  description,
  videoId,
  title,
}) => {
  return (
    <div>
      <S.VideoContainer>
        <S.DescriptionVideo>{description}</S.DescriptionVideo>
        <YoutubeItem videoId={videoId} title={title} />
      </S.VideoContainer>
    </div>
  );
};
