import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 20px;
  background-color: ${({ theme }) => theme.backgroundCard};
  color: ${({ theme }) => theme.text};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DeveloperInfo = styled.p`
  margin: 10px 0;
  font-size: ${({ theme }) => theme.fontSizeMinor};
`;

export const ContactButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
