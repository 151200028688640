import styled from "styled-components";

export const GalleryContainer = styled.div`
  margin-left: 30px;
  background: ${({ theme }) => theme.backgroundCard};
  padding: 40px 40px;
  border-radius: 8px;
  @media (max-width: 768px) {
    margin-left: 0px;
    padding: 15px 15px;
  }
`;

export const ContentProject = styled.div`
  margin-left: 30px;
  background: ${({ theme }) => theme.backgroundCard};
  padding: 40px 40px;
  border-radius: 8px;
  @media (max-width: 768px) {
    margin-left: 0px;
    padding: 15px 25px;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.backgroundCard};
  color: ${({ theme }) => theme.text};
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  flex: 1;
  margin-left: 0px;
  background: ${({ theme }) => theme.backgroundCard};
  padding: 20px 20px;
  border-radius: 8px;
`;

export const Date = styled.h2`
  font-weight: bold;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.subtitle2};
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: ${({ theme }) => theme.fontSize};
  line-height: ${({ theme }) => theme.lineHeight};
  padding: 5px 20px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    padding: 0;
    font-size: ${({ theme }) => theme.fontSizeMinor};
  }
`;

export const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.subtitle1};
`;

export const LogoContainer = styled.div`
  display: flex;
  widht: 100%;
  flex-direction: row;
  background: ${({ theme }) => theme.backgroundCard};
  gap: 16px;
  padding: 20px 20px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    padding: 0;
    transform: scale(0.5);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  background: ${({ theme }) => theme.backgroundCard};
  gap: 16px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    transform: scale(0.5);
  }
`;
