import React from "react";
import { Section } from "../Section";
import profileImage1 from "../../images/avatar_jona.jpg";
import profileImage2 from "../../images/avatar_jona2.jpg";
import { useTranslation } from "react-i18next";
import { ReactComponent as LinkedinIcon } from "../icons/linkedin.svg";
import { ReactComponent as MailIcon } from "../icons/mail.svg";
import { ReactComponent as CVIcon } from "../icons/cv.svg";
import { useTheme } from "styled-components";
import { EMAIL } from "../../constants";
import { ButtonLink } from "../ButtonLink";
import { useLanguage } from "../../LanguageContext";
import * as S from "./HomeSection.styles";

export const HomeSection: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { language } = useLanguage();
  const cv = language === "es" ? "/info/cvES.pdf" : "/info/cvEN.pdf";

  return (
    <Section id={"home"} padding={0}>
      <S.Container>
        <S.AnimatedText>{t("regards")}</S.AnimatedText>
      </S.Container>
      <S.ImageContainer>
        <S.TextContainer>
          <S.Title>
            <h3>{t("mathematician")}</h3>
          </S.Title>
          <S.Title>
            <h3>Front-end</h3>
          </S.Title>
          <S.Title>
            <h3>{t("cad_developer")}</h3>
          </S.Title>
          <S.Title>
            <h3>{t("years_experience")}</h3>
          </S.Title>
        </S.TextContainer>

        <S.ProfileImage $image1={profileImage1} $image2={profileImage2} />

        <S.ButtonContainer>
          <ButtonLink
            animate="true"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/jonatan-p%C3%A9rez-r%C3%B3denas-01bb801a7/",
                "_blank"
              )
            }
          >
            <LinkedinIcon
              stroke={theme.item}
              fill={theme.backgroundItem}
              height={64}
              width={64}
            />
          </ButtonLink>
          <ButtonLink animate="true" offset="30px" href={`mailto:${EMAIL}`}>
            <MailIcon
              stroke={theme.item}
              fill={theme.backgroundItem}
              height={64}
              width={64}
            />
          </ButtonLink>
          <ButtonLink animate="true" href={cv} target="_blank">
            <CVIcon
              stroke={theme.item}
              fill={theme.backgroundItem}
              height={64}
              width={64}
            />
          </ButtonLink>
        </S.ButtonContainer>
      </S.ImageContainer>
    </Section>
  );
};
