export const defaultTheme = {
  fontSize: "1.2em",
  fontSizeMinor: "1em",
  lineHeight: "1.6",
};

export const lightTheme = {
  ...defaultTheme,
  type: "Light",
  background: "#FDFEE0",
  backgroundCard: "#EDD7B0",
  title: "#D4AF84",
  subtitle1: "#B04810",
  subtitle2: "#A18564",
  text: "#313133",
  item: "#967C5D",
  backgroundItem: "#FDFEE0",
  higlight: "#FACC15",
};

export const darkTheme = {
  ...defaultTheme,
  type: "Dark",
  background: "#0A2647",
  backgroundCard: "#144272",
  title: "#3498db",
  subtitle1: "#B09B0E",
  subtitle2: "#3DCCFF",
  text: "#DDD",
  item: "#3498db",
  backgroundItem: "#0A2647",
  higlight: "#f5f5dc",
};
