import React, { ReactNode } from "react";
import { PreviewItem } from "../PreviewItem";
import { Section } from "../Section";
import { Timeline } from "../Timeline";
import { ButtonLink } from "../ButtonLink";
import { ReactComponent as GithubIcon } from "../icons/github.svg";
import { ReactComponent as TryIcon } from "../icons/link.svg";
import { ReactComponent as ProjectIcon } from "../icons/code.svg";
import { useLanguage } from "../../LanguageContext";
import { useTheme } from "styled-components";
import * as S from "./ProjectSection.styles";
import * as dataProjects from "./dataProjects";

export const ProjectSection: React.FC = () => {
  const theme = useTheme();
  const { language } = useLanguage();
  const projects =
    language === "es" ? dataProjects.projectsES : dataProjects.projectsEN;

  const children: ReactNode[] = projects.map((project, index) => (
    <S.ContentProject key={index}>
      <S.Date>{project.date}</S.Date>
      <S.Title>{project.title}</S.Title>
      <S.LogoContainer>{project.icons && project.icons(theme)}</S.LogoContainer>
      <S.ItemContainer>
        <PreviewItem
          imageUrl={project.imageUrl}
          videoUrl={project.videoUrl}
          title={project.subtitle}
          onClick={() => {
            window.open(project.link, "_blank");
          }}
        />
        <S.Content>
          <S.Description>{project.description}</S.Description>
        </S.Content>
      </S.ItemContainer>

      <S.ButtonContainer>
        {project.showCode && (
          <ButtonLink onClick={() => window.open(project.gitHub, "_blank")}>
            <GithubIcon
              stroke={theme.item}
              fill={theme.backgroundItem}
              height={64}
              width={64}
            />
          </ButtonLink>
        )}
        <ButtonLink onClick={() => window.open(project.link, "_blank")}>
          <TryIcon
            stroke={theme.item}
            fill={theme.backgroundItem}
            height={64}
            width={64}
          />
        </ButtonLink>
      </S.ButtonContainer>
    </S.ContentProject>
  ));
  return (
    <Section id={"projects"} icon={ProjectIcon} title={"personal"} delay={1}>
      <Timeline items={children} />
    </Section>
  );
};
