import styled, { createGlobalStyle } from "styled-components";

export const BodyContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  padding-top: 100px;

  padding-left: 15px;
  padding-right: 15px;
`;

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    /* Otras propiedades globales */
  }

  /* WebKit Scrollbar */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.background};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.item};
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.higlight};
  }

  /* Firefox Scrollbar */
  body {
    scrollbar-color: ${({ theme }) => theme.item} ${({ theme }) =>
  theme.background};
    scrollbar-width: thin;
  }


  ::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 4"><path d="M0 4L4 0L8 4" fill="%23${({
      theme,
    }) => theme.item.replace("#", "")}" /></svg>');
    background-repeat: no-repeat;
    background-position: center;
  }

  ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 4"><path d="M0 4L4 0L8 4" fill="%23${({
      theme,
    }) => theme.higlight.replace("#", "")}" /></svg>');
    background-repeat: no-repeat;
    background-position: center;
  }

  ::-webkit-scrollbar-button:single-button:vertical:increment {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 4"><path d="M0 0L4 4L8 0" fill="%23${({
      theme,
    }) => theme.item.replace("#", "")}" /></svg>');
    background-repeat: no-repeat;
    background-position: center;
  }

  ::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 4"><path d="M0 0L4 4L8 0" fill="%23${({
      theme,
    }) => theme.higlight.replace("#", "")}" /></svg>');
    background-repeat: no-repeat;
    background-position: center;
  }
`;
