import styled, { keyframes } from "styled-components";

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div<{ $delay: number; $padding: number }>`
  width: 100%;
  padding: 0 ${({ $padding }) => $padding}px;
  padding-bottom: 50px;
  z-index: 10000;
  overflow: hidden;
  opacity: 0;
  animation: ${opacityAnimation} 0.7s ease-out forwards;
  animation-delay: ${({ $delay }) => $delay}s;
`;

export const Title = styled.h1`
  display: flex;
  gap: 10px;
  color: ${({ theme }) => theme.item};
  opacity: 1;
  align-items: center;
`;

export const SVGContainer = styled.div`
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.item};
  background: ${({ theme }) => theme.backgroundCard};
`;
