import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Animation = styled.span`
  position: relative;
  display: inline-flex;
  overflow: hidden;
  border-radius: 10px;
  padding: 4px;

  &::before {
    border-radius: 5%;
    content: "";
    position: absolute;
    top: -1000%;
    left: -1000%;
    right: -1000%;
    bottom: -1000%;
    background: conic-gradient(
      from 90deg at 50% 50%,
      ${({ theme }) => theme.background} 0%,
      ${({ theme }) => theme.item} 100%
    );
    animation: ${spin} 4s linear infinite;
  }

  @media (hover: hover) {
    &:hover::before {
      animation-play-state: running;
    }

    &:not(:hover)::before {
      animation-play-state: paused;
      background: ${({ theme }) => theme.background};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5%;
`;
export const Content = styled.a`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.background};
  border-radius: 10px;
  cursor: pointer;
  backdrop-filter: blur(15px);
  white-space: nowrap;
  user-select: none;
`;
