import React from "react";
import { Section } from "../Section";
import { ReactComponent as SkillIcon } from "../icons/medal.svg";
import { ReactComponent as HTMLIcon } from "../icons/html5.svg";
import { ReactComponent as CSSIcon } from "../icons/css3.svg";
import { ReactComponent as JavaScriptIcon } from "../icons/js.svg";
import { ReactComponent as TypeScriptIcon } from "../icons/ts.svg";
import { ReactComponent as CPPIcon } from "../icons/cpp.svg";
import { ReactComponent as CSharpIcon } from "../icons/csharp.svg";
import { ReactComponent as ReactIcon } from "../icons/react.svg";
import { ReactComponent as ReduxIcon } from "../icons/redux.svg";
import { ReactComponent as StyledComponents } from "../icons/styledComponents.svg";
import { ReactComponent as Storybook } from "../icons/storybook.svg";
import { ReactComponent as ThreeJSIcon } from "../icons/threejs.svg";
import { ReactComponent as OpenGLIcon } from "../icons/opengl.svg";
import { ReactComponent as WebGLIcon } from "../icons/webgl.svg";
import { ReactComponent as NodeJSIcon } from "../icons/nodejs.svg";
import { ReactComponent as NPMIcon } from "../icons/npm.svg";
import { ReactComponent as NextJSIcon } from "../icons/nextjs.svg";
import { ReactComponent as DockerIcon } from "../icons/docker.svg";
import { ReactComponent as MySQLIcon } from "../icons/mysql.svg";
import { ReactComponent as MongoDBIcon } from "../icons/mongodb.svg";
import { ReactComponent as JSONIcon } from "../icons/json.svg";
import { ReactComponent as GithubIcon } from "../icons/github.svg";
import { ReactComponent as GitlabIcon } from "../icons/gitlab.svg";
import { ReactComponent as GitIcon } from "../icons/git.svg";
import { ReactComponent as VSCodeIcon } from "../icons/vscode.svg";
import { ReactComponent as FigmaIcon } from "../icons/figma.svg";
import { ReactComponent as AsanaIcon } from "../icons/asana.svg";
import { ReactComponent as JiraIcon } from "../icons/jira.svg";
import { ReactComponent as TrelloIcon } from "../icons/trello.svg";
import { ReactComponent as RhinoIcon } from "../icons/rhino.svg";
import { ReactComponent as BlenderIcon } from "../icons/blender.svg";
import { ReactComponent as SourceTreeIcon } from "../icons/sourcetree.svg";
import { ReactComponent as SmartGitIcon } from "../icons/smartgit.svg";
import { ReactComponent as JesttIcon } from "../icons/jest.svg";
import { ReactComponent as TestingIcon } from "../icons/testingLibrary.svg";
import { ReactComponent as PlaywrightIcon } from "../icons/playwright.svg";
import * as S from "./SkillSection.styles";
import { SkillItem } from "./SkillItem";

export const SkillSection: React.FC = () => {
  return (
    <Section id={"skills"} icon={SkillIcon} title={"skills"} delay={1}>
      <S.SkillContainer>
        <SkillItem svgIcon={HTMLIcon} text="HTML5" />
        <SkillItem svgIcon={CSSIcon} text="CSS3" />
        <SkillItem svgIcon={JavaScriptIcon} text="JavaScript" />
        <SkillItem svgIcon={TypeScriptIcon} text="TypeScript" />
        <SkillItem svgIcon={CPPIcon} text="C++" />
        <SkillItem svgIcon={CSharpIcon} text="C#" />
        <SkillItem svgIcon={ReactIcon} text="React" />
        <SkillItem svgIcon={ReduxIcon} text="Redux" />
        <SkillItem svgIcon={StyledComponents} text="Styled components" />
        <SkillItem svgIcon={Storybook} text="Storybook" />
        <SkillItem svgIcon={ThreeJSIcon} text="three.js" />
        <SkillItem svgIcon={OpenGLIcon} fill={true} text="OpenGL" />
        <SkillItem svgIcon={WebGLIcon} fill={true} text="WebGL" />
        <SkillItem svgIcon={NodeJSIcon} text="Node.js" />
        <SkillItem svgIcon={NPMIcon} fill={true} text="npm" />
        <SkillItem svgIcon={NextJSIcon} text="Next.js" />
        <SkillItem svgIcon={DockerIcon} text="Docker" />
        <SkillItem svgIcon={MySQLIcon} text="MySQL" />
        <SkillItem svgIcon={MongoDBIcon} text="MongoDB" />
        <SkillItem svgIcon={JSONIcon} text="JSON" />
        <SkillItem svgIcon={GithubIcon} text="Github" />
        <SkillItem svgIcon={GitlabIcon} text="Gitlab" />
        <SkillItem svgIcon={GitIcon} fill={true} text="Git" />
        <SkillItem svgIcon={SourceTreeIcon} fill={true} text="SourceTree" />
        <SkillItem svgIcon={SmartGitIcon} fill={true} text="SmartGit" />
        <SkillItem svgIcon={VSCodeIcon} fill={true} text="VS Code" />
        <SkillItem svgIcon={FigmaIcon} text="Figma" />
        <SkillItem svgIcon={AsanaIcon} text="Asana" />
        <SkillItem svgIcon={JiraIcon} fill={true} text="Jira" />
        <SkillItem svgIcon={TrelloIcon} text="Trello" />
        <SkillItem svgIcon={RhinoIcon} text="Rhinoceros" />
        <SkillItem svgIcon={BlenderIcon} text="Blender" />
        <SkillItem svgIcon={JesttIcon} fill={true} text="Jest" />
        <SkillItem svgIcon={TestingIcon} text="Testing library" />
        <SkillItem svgIcon={PlaywrightIcon} fill={true} text="Playwright" />
      </S.SkillContainer>
    </Section>
  );
};
