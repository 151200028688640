import namek_ship from "../../images/namek_ship.jpg";
import components from "../../images/components.jpg";
import tandem_img from "../../images/tandem63.jpg";
import { DefaultTheme } from "styled-components";
import { ReactComponent as TypeScriptIcon } from "../icons/ts.svg";
import { ReactComponent as ReactIcon } from "../icons/react.svg";
import { ReactComponent as ThreeJSIcon } from "../icons/threejs.svg";
import { ReactComponent as NextJSIcon } from "../icons/nextjs.svg";
import { ReactComponent as MySQLIcon } from "../icons/mysql.svg";

export const projectsES = [
  {
    date: "Agosto 2024",
    imageUrl: tandem_img,
    videoUrl: "/videos/demo_tandem63.mp4",
    title: "Tandem #63",
    subtitle: "Diseño, desarrollo y mantenimiento de página web para empresa",
    link: "https://tandem63.com/",
    description: `He diseñado y desarrollado la página web para el gimnasio Tandem #63.
        He desarrollado tanto la parte front-end como back-end. La web cubre las necesidades que el cliente me pidió.
        Todos los componentes han sido desarrollados por mí desde cero usando React.
        El diseño es capaz de acaptarse de manera resopnsiva a cualquier dispositivo. Lo he desarrollado usando NextJS. Uso de SSR para mejorar la experiencia del usuario.
        La web permite gestión de usuarios, donde cada cual tiene su perfil personalizado para guardar sus estadísticas y
        toda la información necesaria para el día a día del usuario que se ha valorado desde dentro del gimnasio.
        También existe un foro para los miembros.`,
    icons: (theme: DefaultTheme) => (
      <>
        <TypeScriptIcon
          stroke={theme.item}
          strokeWidth={1}
          height={64}
          width={64}
        />
        <ReactIcon stroke={theme.item} strokeWidth={1} height={64} width={64} />
        <NextJSIcon
          stroke={theme.item}
          strokeWidth={1}
          height={64}
          width={64}
        />
        <MySQLIcon stroke={theme.item} strokeWidth={1} height={64} width={64} />
      </>
    ),
    showCode: false,
  },
  {
    date: "Abril 2024",
    imageUrl: namek_ship,
    videoUrl: "/videos/demo_namek.mp4",
    title: "Find Spheres",
    subtitle: "Pruébalo!",
    link: "https://findspheres.netlify.app/",
    gitHub: "https://github.com/JRodenPer/find-spheres",
    description: `A este juego en 3D (versión beta) lo he llamado "Find Spheres"
        y está basado en el planeta Namek del universo de Dragon Ball.
        Creado desde cero en TypeScript, usando React y three.js.
        Crea escenarios aleatorios cada vez que se recarga para que ninguna
        partida sea igual. ¡Anímate y pasa un buen rato buscando las esferas del dragón!
        Para una mejor experiencia, no jugar en pantallas táctiles`,
    icons: (theme: DefaultTheme) => (
      <>
        <TypeScriptIcon
          stroke={theme.item}
          strokeWidth={1}
          height={64}
          width={64}
        />
        <ReactIcon stroke={theme.item} strokeWidth={1} height={64} width={64} />
        <ThreeJSIcon
          stroke={theme.item}
          strokeWidth={1}
          height={64}
          width={64}
        />
      </>
    ),
    showCode: true,
  },
  {
    date: "June 2024",
    imageUrl: components,
    videoUrl: "/videos/demo_components.mp4",
    title: "Catálogo de componentes",
    subtitle: "Personaliza tu web!",
    link: "https://jr-custom-components.netlify.app/",
    gitHub: "",
    description: `Este es un catálogo de componentes que he creado para aplicaciones React.
        La idea es ir añadiendo cada vez más componentes.`,
    icons: (theme: DefaultTheme) => (
      <>
        <TypeScriptIcon
          stroke={theme.item}
          strokeWidth={1}
          height={64}
          width={64}
        />
        <ReactIcon stroke={theme.item} strokeWidth={1} height={64} width={64} />
      </>
    ),
    showCode: false,
  },
];

export const projectsEN = [
  {
    date: "August 2024",
    imageUrl: tandem_img,
    videoUrl: "/videos/demo_tandem63.mp4",
    title: "Tandem #63",
    subtitle: "Design, development, and maintenance of the company’s website.",
    link: "https://tandem63.com/",
    description: `I designed and developed the website for the Tandem #63 gym.
      I developed both the front-end and back-end. The website meets the needs the client requested.
      All components have been developed by me from scratch using React.
      The design is responsive and adapts to any device. I developed it using NextJS, utilizing SSR to improve the user experience.
      The website includes user management, where each user has a personalized profile to store their statistics and all the necessary day-to-day information valued by the gym itself.
      There is also a forum for members.`,
    icons: (theme: DefaultTheme) => (
      <>
        <TypeScriptIcon
          stroke={theme.item}
          strokeWidth={1}
          height={64}
          width={64}
        />
        <ReactIcon stroke={theme.item} strokeWidth={1} height={64} width={64} />
        <NextJSIcon
          stroke={theme.item}
          strokeWidth={1}
          height={64}
          width={64}
        />
        <MySQLIcon stroke={theme.item} strokeWidth={1} height={64} width={64} />
      </>
    ),
    showCode: false,
  },
  {
    date: "April 2024",
    imageUrl: namek_ship,
    videoUrl: "/videos/demo_namek.mp4",
    title: "Find Spheres",
    subtitle: "Click to test!",
    link: "https://findspheres.netlify.app/",
    gitHub: "https://github.com/JRodenPer/find-spheres",
    description: `I have called this 3D game (beta version) "Find Spheres" and it is based on the Namek planet from
        the Dragon Ball universe. Built from scratch in TypeScript, using React and three.js.
        Generates random scenarios every time you reload, ensuring no two games are the same.
        Dive in and have fun searching for the Dragon Balls! Don't play on touch screen for better experience.`,
    icons: (theme: DefaultTheme) => (
      <>
        <TypeScriptIcon
          stroke={theme.item}
          strokeWidth={1}
          height={64}
          width={64}
        />
        <ReactIcon stroke={theme.item} strokeWidth={1} height={64} width={64} />
        <ThreeJSIcon
          stroke={theme.item}
          strokeWidth={1}
          height={64}
          width={64}
        />
      </>
    ),
    showCode: true,
  },
  {
    date: "June 2024",
    imageUrl: components,
    videoUrl: "/videos/demo_components.mp4",
    title: "Catalog of components",
    subtitle: "Customize your web!",
    link: "https://jr-custom-components.netlify.app/",
    gitHub: "",
    description: `This is a catalog of components that I have created for React applications.
        The idea is to add more and more components.`,
    icons: (theme: DefaultTheme) => (
      <>
        <TypeScriptIcon
          stroke={theme.item}
          strokeWidth={1}
          height={64}
          width={64}
        />
        <ReactIcon stroke={theme.item} strokeWidth={1} height={64} width={64} />
      </>
    ),
    showCode: false,
  },
];
