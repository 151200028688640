import React from "react";
import * as S from "./Footer.styles";
import { Button } from "../Button";
import { EMAIL } from "../../constants";
import { ReactComponent as MailIcon } from "../icons/mail.svg";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";

export const Footer: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <S.FooterContainer>
      <S.DeveloperInfo>{t("footer")}</S.DeveloperInfo>
      <S.ContactButtonContainer>
        <p>jrodenper@gmail.com</p>
        <Button
          href={`mailto:${EMAIL}`}
          child={<MailIcon stroke={theme.item} fill={theme.backgroundItem} />}
        ></Button>
      </S.ContactButtonContainer>
    </S.FooterContainer>
  );
};
