import React from "react";
import { Section } from "../Section";
import profileImage from "../../images/jona_profile.jpg";
import { ReactComponent as ProfileIcon } from "../icons/profile.svg";
import { useLanguage } from "../../LanguageContext";
import * as S from "./AboutSection.styles";
import * as dataAbout from "./dataAbout";

export const AboutSection: React.FC = () => {
  const { language } = useLanguage();
  const about = language === "es" ? dataAbout.aboutES : dataAbout.aboutEN;
  return (
    <Section id={"about"} icon={ProfileIcon} title={"about"} delay={1}>
      <S.AboutContainer>
        <S.ProfileImage src={profileImage} alt="Profile Image" />
        <S.Description>{about.job}</S.Description>
        <S.Description>{about.hobbies}</S.Description>
        <S.Description>{about.ending}</S.Description>
      </S.AboutContainer>
    </Section>
  );
};
