import React from "react";
import * as S from "./SkillSection.styles";
import { useTheme } from "styled-components";

interface SkillItemProps {
  text?: string;
  svgIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  fill?: boolean;
}

export const SkillItem: React.FC<SkillItemProps> = ({
  text,
  svgIcon: SvgIcon,
  fill = false,
}) => {
  const theme = useTheme();

  if (!SvgIcon) {
    return null;
  }
  return (
    <S.LogoItem>
      <S.SVGContainer>
        <SvgIcon
          stroke={theme.item}
          fill={fill ? theme.item : "none"}
          strokeWidth={1}
          height={64}
          width={64}
        />
      </S.SVGContainer>
      {text && text}
    </S.LogoItem>
  );
};
