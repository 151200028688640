import styled from "styled-components";

export const Content = styled.div`
  margin-left: 30px;
  background: ${({ theme }) => theme.backgroundCard};
  padding: 40px 40px;
  border-radius: 8px;
  @media (max-width: 768px) {
    margin-left: 0px;
    padding: 15px 15px;
  }
`;

export const Date = styled.h2`
  font-weight: bold;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.subtitle2};
`;

export const Company = styled.h2`
  font-weight: bold;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.subtitle1};
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: ${({ theme }) => theme.fontSize};
  line-height: ${({ theme }) => theme.lineHeight};
  padding: 5px 20px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizeMinor};
  }
`;

export const DescriptionVideo = styled.p`
  color: ${({ theme }) => theme.text};
  font-size: ${({ theme }) => theme.fontSize};
  line-height: ${({ theme }) => theme.lineHeight};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizeMinor};
  }
`;

export const VideoContainer = styled.div`
  padding: 20px;
  border: 4px solid ${({ theme }) => theme.item};
  background: ${({ theme }) => theme.backgroundItem};
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
`;
