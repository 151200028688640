import React, { ReactNode } from "react";
import * as S from "./BackgroundContainer.styles";

interface BackgroundContainerProps {
  children?: ReactNode;
}

export const BackgroundContainer: React.FC<BackgroundContainerProps> = ({
  children,
}) => {
  return <S.Container>{children}</S.Container>;
};
