import styled, { keyframes } from "styled-components";

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const SkillContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 45px;
  padding: 50px 20px;
  background-color: ${({ theme }) => theme.backgroundCard};
  color: ${({ theme }) => theme.text};
  border-radius: 8px;
  opacity: 0;

  animation: ${opacityAnimation} 2s ease-out forwards;

  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

export const LogoItem = styled.div`
  display: flex;
  flex: 1;
  max-width: 70px;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.backgroundCard};
  gap: 16px;
  padding: 20px 20px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    padding: 0;
    font-size: 0.7em;
  }
`;

export const SVGContainer = styled.div`
  @media (max-width: 768px) {
    transform: scale(0.5);
  }
`;
