import { ReactNode, useState } from "react";
import { Section } from "../Section";
import { Timeline } from "../Timeline";
import { useTranslation } from "react-i18next";
import { Knowmore } from "./KnowMore";
import { Button } from "../Button";
import { ReactComponent as ExperienceIcon } from "../icons/computer.svg";
import { useLanguage } from "../../LanguageContext";
import * as dataJobs from "./dataJobs";
import * as S from "./ExperienceSection.styles";

export const ExperienceSection = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const { t } = useTranslation();
  const { language } = useLanguage();
  const handleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const timelineItems =
    language === "es" ? dataJobs.timelineItemsES : dataJobs.timelineItemsEN;

  const children: ReactNode[] = timelineItems.map((item, index) => (
    <S.Content key={index}>
      <S.Date>{item.date}</S.Date>
      <S.Company>{item.company}</S.Company>
      <S.Description>{item.description}</S.Description>
      {expandedIndex === index && (
        <Knowmore
          description={item.descriptionVideo}
          videoId={item.videoId}
          title={item.company}
        />
      )}
      <Button
        text={expandedIndex === index ? t("hide") : t("know_more")}
        onClick={() => handleExpand(index)}
      />
    </S.Content>
  ));

  return (
    <Section
      id={"experience"}
      title={"experience"}
      icon={ExperienceIcon}
      delay={1}
    >
      <Timeline items={children} />
    </Section>
  );
};
